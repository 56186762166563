import 'es6-promise/auto';

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

//import bindArrowToTop from './components/arrow-to-top';
//import bindScrollLinks from './components/scroll-links';

import init from "./vbcn";
import { variousTriggers, variousStuff } from "./components/old-stuff";

function initGlobal() {

    //bindArrowToTop();
    //bindScrollLinks();

    init();
    variousTriggers();
    variousStuff();

}

function checkMap() {
    // console.log('mapLoading');
    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('maps') && window.cookieState.allowedData.options['maps'] ) {
        $('.googlemaps-iframe').show().attr('src', $('.googlemaps-iframe').data('src'));
    }
}

document.addEventListener('DOMContentLoaded', function() {
    initGlobal();


    checkMap();
    $('.js-vbcn-execute, #vbcn-accept-preset, #vbcn-save').on('click', checkMap);

});
