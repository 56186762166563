window.$ = window.jQuery = require('jquery');

const $ = jQuery;

export function variousTriggers() {

    $('.navbar-toggle').on('click', function(){
        $('#navbar').show();
    });

    $('.navbar-close').on('click', function(){
        $('#navbar').hide();
    });

}

export function variousStuff() {

   /* var myOddEven = 0;
    $('.wrap-row').find('.row').each(function( index ) {
        if(myOddEven == 0){
            $(this).addClass( "odd");
            myOddEven = 1;
        }else{
            $(this).addClass( "even");
            myOddEven = 0;
        }
    });*/

    // Add menu separator on mobile
    $('<hr>').insertAfter('.navbar-nav li.focusPage:last');

    // Checking Videos
   /* if ($('figure.video').length > 0) {
        $('figure.video').fitVids();
    }*/

   /*if ($('div[class^="owl"]')) {
       $('div[class^="owl"]').each(function() {
           target = $(this);
           // If the Slideshow is in COL-SM-12
           if (target.hasClass('big-owl')) {
               // If the Slideshow has more than one item
               if (target.children().length > 1) {
                   console.log(target);
                   $(this).owlCarousel({
                       loop: true,
                       autoplay: true,
                       dotsEach: 1,
                       items: 1,
                       responsive : {
                           768 : {
                               items : 3
                           }
                       }
                   });
               } else {
                   target.owlCarousel({
                       items: 1,
                       dots: false
                   });
               }
           } else {
               // If the Slideshow is in COL-SM-6
               if (target.children().length > 1) {
                   target.owlCarousel({
                       loop: true,
                       items: 1,
                       autoplay: true,
                       dotsEach: 1
                   });
               } else {
                   target.owlCarousel({
                       items: 1,
                       dots: false
                   });
               }

           }
       });
   }*/

}
